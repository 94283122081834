import React from "react";
import "./main-layout.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-header-container">
        <div className="footer-header-container-left">Stay</div>
        <div className="footer-header-container-center">
          <h4 className="footer-header">Mushies Brand</h4>
        </div>
        <div className="footer-header-container-right">Organic</div>
      </div>
      <hr className="footer-hr" />
      <div className="footer-links">
        <a
          className="footer-link"
          href="https://www.instagram.com/mrmushiesbrand/"
          target="_blank"
          rel="noreferrer"
        >
          Instagram
        </a>
      </div>
      <hr className="footer-hr" />
      <h5 className="copyright">Mushies Brand 2022</h5>
    </div>
  );
};

export default function MainLayout(props) {
  return (
    <div className="main-layout">
      {props.children}
      <Footer />
    </div>
  );
}
