import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import MainLayout from "./components/layouts/main-layout";
import ValidationPage from "./pages/validation/validation";

export default function App() {
  return (
    <MainLayout>
      <BrowserRouter>
        <Routes>
          <Route path="/validate/:id" element={<ValidationPage />}></Route>
        </Routes>
      </BrowserRouter>
    </MainLayout>
  );
}
