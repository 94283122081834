import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../util/API";
import "./validation.css";

const InvalidProductId = () => {
  return (
    <div className="screen-center text-center">
      <div>
        <i
          className="fa-solid fa-circle-question mb-4"
          style={{ fontSize: "5rem" }}
        ></i>
      </div>
      <h2 className="mb-4">Invalid Product Key</h2>
      <h4 className="text-center">
        We&apos;re sorry, but this product key is invalid, which means this
        product can&apos;t be verified.
      </h4>
    </div>
  );
};

const RetrievingAuthenticity = () => {
  return (
    <div className="screen-center">
      <h2 className="mb-4" style={{ fontWeight: "200" }}>
        Retrieving Product Info...
      </h2>
      <div>
        <i className="fas fa-circle-notch fa-spin fa-2x"></i>
      </div>
    </div>
  );
};

const ErrorValidatingProduct = () => {
  return (
    <div className="screen-center text-center">
      <h2 className="mb-4" style={{ fontWeight: "200" }}>
        Error Validating Product
      </h2>
      <h4>
        We&apos;re sorry, but your product couldn&apos;t be validated at this
        time. Please contact IT support for more info.
      </h4>
    </div>
  );
};

const NotAuthenticProductView = ({ id }) => {
  return (
    <>
      <h2 className="m-2 p-2 text-center">Product Test Report</h2>

      <div className="w-90 m-auto text-center">
        <h4 className="text-danger mb-4">YOUR PRODUCT MAY NOT BE AUTHENTIC</h4>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="" height="20%" width="20%" src={"/invalid.png"} />
        </div>
        <h3>Serial Number: {id}</h3>
      </div>

      <hr className="my-4" />

      <div className="w-90 m-auto text-center">
        <span>
          This serial number does not exist in our system or has been scanned
          more than five times.
        </span>
      </div>
    </>
  );
};

const CompromisedProductView = () => {
  return (
    <>
      <h2 className="m-2 p-2 text-center">Product Test Report</h2>

      <div className="w-90 m-auto text-center">
        <h4 className="text-danger mb-4">YOUR PRODUCT HAS BEEN COMPROMISED</h4>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="" height="20%" width="20%" src={"/invalid.png"} />
        </div>
      </div>

      <hr className="my-4" />

      <div className="w-90 m-auto text-center">
        <span>
          This code has been deemed as compromised. Someone has faked our
          stickers and is using it on product that is not authentic.
        </span>
      </div>
    </>
  );
};

const PlaceholderProductView = ({ id, productInfo }) => {
  return (
    <>
      <h3 className="m-2 p-2 text-center">Product Test Report</h3>

      <div className="w-90 m-auto text-center">
        <h4 className="text-success mb-4">YOUR PRODUCT IS AUTHENTIC</h4>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="" src={"/valid.png"} className="validIcon" />
        </div>
        <h4>Serial Number: {id}</h4>
        <h4>Scan Count: {productInfo.timesViewed}</h4>
      </div>

      <hr className="my-4" />

      <div className="w-90 m-auto">
        <h2>Product Profile</h2>
        <div>Psilocybin Amount: 4 g</div>
      </div>
    </>
  );
};

const AuthenticProductView = ({ id, productInfo }) => {
  return (
    <>
      <h3 className="m-2 p-2 text-center">Product Test Report</h3>

      <div className="w-90 m-auto text-center">
        <h4 className="text-success mb-4">YOUR PRODUCT IS AUTHENTIC</h4>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="" src={"/valid.png"} className="validIcon" />
        </div>
        <h4>Serial Number: {id}</h4>
        <h4>Scan Count: {productInfo.timesViewed}</h4>
      </div>

      <hr className="my-4" />

      <div className="w-90 m-auto">
        <h2>Product Profile</h2>
        <div>Flavor Description: {productInfo.flavorDesc}</div>
        <div>Psilocybin Amount: {productInfo.psilocybin} g</div>
      </div>
    </>
  );
};

export default function ValidationPage() {
  const { id } = useParams();

  const [productInfo, setProductInfo] = useState({
    id: id,
    batchId: "",
    labelId: "",
    SKU: "",
    flavorDesc: "",
    psilocybin: "4",
    timesViewed: "",
    isActive: true,
    isCompromised: false,
    isClaimed: null,
  });
  const [loadingValidation, setLoadingValidation] = useState(true);
  const [errorValidatingProduct, setErrorValidatingProduct] = useState(false);
  const [productIdIsInvalid, setProductIdIsInvalid] = useState(false);
  const [productIsAuthentic, setProductIsAuthentic] = useState(false);
  const [productHasNoBatch, setProductHasNoBatch] = useState(false);
  const [productIsCompromised, setProductIsCompromised] = useState(false);

  const validateProductById = async () => {
    try {
      const result = await API.get(`validate?id=${id}`);
      console.log(result);
      if (result && result.status === 200 && result.data) {
        const product = result.data;
        if (product.isCompromised === true) {
          // Compromised product
          setProductIsCompromised(true);
        } else if (product.batchId === null) {
          // Valid code, missing batch entry
          if (product.timesViewed <= 5) {
            const defaultInfoWithTimesViewed = {
              ...productInfo,
              timesViewed: product.timesViewed,
              isCompromised: product.isCompromised,
              isClaimed: product.isClaimed,
            };
            setProductInfo(defaultInfoWithTimesViewed);
            setProductIsAuthentic(true);
            setProductHasNoBatch(true);
          }
        } else {
          // Valid code and batch entry
          if (product.isActive) {
            setProductInfo(product);
            setProductIsAuthentic(true);
          }
        }
      } else if (result && result.status === 204) {
        setProductIdIsInvalid(true);
      }
    } catch (err) {
      if (err.status === 400) {
        setProductIdIsInvalid(true);
      } else {
        setErrorValidatingProduct(true);
      }
    } finally {
      setLoadingValidation(false);
    }
  };

  useEffect(() => {
    if (id && /^[0-9A-Za-z-_~]{11,12}$/.test(id)) {
      validateProductById();
    } else {
      setProductIdIsInvalid(true);
      setLoadingValidation(false);
    }
  }, []);

  return (
    <div className="validationPageContainer">
      <div className="mb-4">
        <img alt="logo" className="logo" src={"/logo1.png"} />
      </div>
      {loadingValidation ? (
        <RetrievingAuthenticity />
      ) : productIdIsInvalid ? (
        <InvalidProductId />
      ) : errorValidatingProduct ? (
        <ErrorValidatingProduct />
      ) : productIsCompromised ? (
        <CompromisedProductView />
      ) : !productIsAuthentic ? (
        <NotAuthenticProductView id={id} />
      ) : productHasNoBatch ? (
        <PlaceholderProductView id={id} productInfo={productInfo} />
      ) : (
        <AuthenticProductView id={id} productInfo={productInfo} />
      )}
    </div>
  );
}
